import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useLocalization } from "gatsby-theme-i18n";
import Mail from "../services/MailService";
import Modal from "./Modal";
import { theme } from "../themeV2";

const EMAIL_REGEX = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const validateEmail = (email) => EMAIL_REGEX.test(email);
const validateName = (name) => name && name.length >= 3;

function ProductModal({ onClose, subject = "Allfunds Connect", buttonColor = "secondary" }) {
  const { locale } = useLocalization();
  const [data, setData] = useState({
    subject,
    type: "product",
    product: "alternatives-request",
    from: "noreply@allfunds.com",
    name: "",
    to: "",
  });

  const [touched, setTouched] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const sendRequest = () => {
    Mail.sendMail(data, { lang: locale }).then(({ status }) => {
      if (status === 200) {
        onClose();
      }
    });
  };

  const validateForm = () => validateEmail(data.to) && validateName(data.name);

  const handleSubmit = (e) => {
    e.preventDefault();
    setTouched(true);
    if (validateForm()) {
      sendRequest();
    }
  };

  return (
    <Modal open width={600} onClose={onClose} backgroundColor="white">
      <Stack spacing={1.5} textAlign="center">
        <Typography variant="h4">
          <Trans>Send request information</Trans>
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label={<Trans>Full name</Trans>}
              variant="outlined"
              fullWidth
              name="name"
              value={data.name}
              onChange={handleInputChange}
              error={touched && !validateName(data.name)}
              helperText={
                touched && !validateName(data.name) ? (
                  <Trans>Name must be at least 3 characters long</Trans>
                ) : null
              }
              required
            />
            <TextField
              label={<Trans>Email</Trans>}
              variant="outlined"
              fullWidth
              name="to"
              value={data.to}
              onChange={handleInputChange}
              error={touched && !validateEmail(data.to)}
              helperText={
                touched && !validateEmail(data.to) ? (
                  <Trans>Invalid email address</Trans>
                ) : null
              }
              required
            />
            <Stack alignItems="flex-end">
              <Button
                size="large"
                color={buttonColor}
                variant="contained"
                sx={{
                  height: "50px",
                  width: "fit-content",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: theme.palette.colors.red.dark,
                  },
                }}
                type="submit"
              >
                <Typography
                  variant="info"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "white !important",
                  }}
                >
                  <Trans>Request information</Trans>
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Modal>
  );
}

export default ProductModal;
