import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import parse from "html-react-parser";
import React from "react";
import { navigate } from "gatsby";
import default_image from "../images/headers/esg.png";
import ipadImage from "../images/esg/home/ipad.png";
import Container from "./Container";
import ProductModal from "./ProductModal";
import logoPositive from "../images/logo.png";

function HeroV2({
  kind,
  title,
  description,
  homePage = false,
  backgroundImage = default_image,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const renderContent = (_title, _description) => {
    const currentTitle = title || _title;
    const currentDescription = description || _description;

    return (
      <Container>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            spacing={1.5}
            maxWidth={(theme) => theme.spacing(62)}
            textAlign={{ xs: "center", sm: "start" }}
          >
            <img
              width={175}
              alt="allfunds_logo"
              src={logoPositive}
              style={{
                cursor: "pointer",
                ...(isMobile && { margin: "0 auto" }),
              }}
              onClick={() => navigate("/")}
            />
            {/* {isMobile && <img src={ipadImage} width="100%" />} */}
            {kind && (
              <Typography fontWeight={700} fontSize={22} color="#0E8F61">
                {kind}
              </Typography>
            )}
            {currentTitle && (
              <Box>
                <Typography
                  variant="h1"
                  component="h2"
                  color="inherit.main"
                  lineHeight={1}
                >
                  {currentTitle}
                </Typography>
              </Box>
            )}
            {currentDescription && (
              <Box>
                <Typography color="white" lineHeight="28px" fontSize={20}>
                  {typeof currentDescription === "object"
                    ? currentDescription
                    : parse(currentDescription)}
                </Typography>
              </Box>
            )}
            <Button
              color="corporate"
              variant="contained"
              onClick={() => setShowModal("Sustainability Navigator")}
              sx={{
                height: "36px",
                boxShadow: "none",
                width: "fit-content",
                ...(isMobile && {
                  margin: (theme) => `${theme.spacing(1.5)} auto !important`,
                }),
              }}
            >
              <Typography
                sx={{
                  color: "white !important",
                }}
              >
                <Trans>Request info</Trans>
              </Typography>
            </Button>
          </Stack>
          {!isMobile && <img src={ipadImage} alt="ipad_image" height={400} />}
        </Stack>
      </Container>
    );
  };

  return (
    <Box>
      <StyledBox homePage={homePage}>
        <Box
          className="background-image"
          sx={{
            display: "grid",
            placeContent: "center",
            backgroundImage: `url(${backgroundImage})`,
            "> div": {
              display: "grid",
              placeContent: "center",
            },
          }}
        >
          {renderContent()}
        </Box>
      </StyledBox>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="ESG - Sustainability Navigator"
        />
      )}
    </Box>
  );
}

const StyledBox = styled(Box)(
  ({ homePage }) => `
  min-height: ${homePage ? "740px" : "650px"};
  position: relative;
  .background-image {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    #vid{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: -1;
    }
  }
`,
);

export default HeroV2;
