import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import _ from "lodash";
import React, { useState } from "react";
import Container from "../../components/Container";
import HeroV2 from "../../components/HeroV2";
import ProductModal from "../../components/ProductModal";
import firstImage from "../../images/esg/home/first.png";
import secondImage from "../../images/esg/home/second.png";
import thirdImage from "../../images/esg/home/third.png";
import blueIcon from "../../images/icons/blue.png";
import pinkIcon from "../../images/icons/pink.png";
import yellowIcon from "../../images/icons/yellow.png";
import { theme } from "../../theme";
import Footer from "../../components/footer";

// const CASES_ITEMS = [
//   {
//     title: <Trans>Wealth Managers & Private Bankers</Trans>,
//     items: [
//       <Trans>Fund Distribution Solutions</Trans>,
//       <Trans>Wealth Management Technology (ATS)</Trans>,
//     ],
//   },
//   {
//     title: <Trans>Insurance Companies</Trans>,
//     items: [
//       <Trans>Fund Distribution Solutions</Trans>,
//       <Trans>Unit Linked solutions</Trans>,
//       <Trans>Agents and commercial network solutions</Trans>,
//     ],
//   },
//   {
//     title: <Trans>Asset Management</Trans>,
//     items: [
//       <Trans>Fund Distribution Solutions</Trans>,
//       <Trans>Digital Solutions</Trans>,
//       <Trans>Data & Analytics</Trans>,
//       <Trans>Reporting & Compliance</Trans>,
//       <Trans>ESG solutions</Trans>,
//     ],
//   },
// ]

const CHECK_ITEMS = [
  {
    title: <Trans>Upload your portfolio</Trans>,
    description: <Trans>Upload your portfolio composition in one click.</Trans>,
  },
  {
    title: <Trans>One-Click Analysis</Trans>,
    description: <Trans>Check main sustainable features in seconds.</Trans>,
  },
];

const BUILD_ITEMS = [
  {
    title: <Trans>Define</Trans>,
    description: (
      <Trans>
        Define your sustainable strategy following a SFDR framework.
      </Trans>
    ),
  },
  {
    title: <Trans>Create</Trans>,
    description: (
      <Trans>
        Develop a sustainable fund portfolio that meets your specific
        objectives.
      </Trans>
    ),
  },
  {
    title: <Trans>Analyse</Trans>,
    description: (
      <Trans>
        Gain actionable insights that optimize your fund’s sustainable features.
      </Trans>
    ),
  },
];

const ITEMS = [
  {
    icon: pinkIcon,
    color: "#C22C74",
    title: <Trans>Launch or reclassify an art. 8 or 9 fund</Trans>,
    description: (
      <Trans>
        Effortlessly launch or upgrade your funds to Art. 8 or 9 status with our
        intuitive platform.
      </Trans>
    ),
  },
  {
    icon: yellowIcon,
    color: "#FFD07C",
    title: (
      <Trans>
        Transform ESG and Sustainability analysis into an in-depth report
      </Trans>
    ),
    description: (
      <Trans>
        Generate comprehensive ESG reports that deliver actionable insights for
        your portfolios.
      </Trans>
    ),
  },
  {
    icon: blueIcon,
    color: "#447CCB",
    title: <Trans>Gain key insights for existing art. 8 or 9 funds</Trans>,
    description: (
      <Trans>
        Analyze your current funds for critical insights that can enhance your
        sustainable investment strategy.
      </Trans>
    ),
  },
];

function EsgHome() {
  const [showModal, setShowModal] = useState(false);

  const renderRequestButton = () => {
    return (
      <Button
        color="corporate"
        variant="contained"
        onClick={() => setShowModal(true)}
        sx={{
          height: "36px",
          boxShadow: "none",
          width: "fit-content",
        }}
      >
        <Typography
          sx={{
            color: "white !important",
          }}
        >
          <Trans>Request info</Trans>
        </Typography>
      </Button>
    );
  };

  const renderItem = ({ title, description, color, icon }) => {
    return (
      <Stack
        spacing={1}
        sx={{
          p: 2,
          minWidth: { xs: "100%", sm: "250px" },
          maxWidth: "300px",
          borderRadius: "10px",
          border: `1px solid ${color}`,
        }}
      >
        <img alt={title} width={22} height={22} src={icon} />
        <Typography fontWeight={700}>{title}</Typography>
        <Typography color="rgba(14, 35, 64, 0.60)">{description}</Typography>
      </Stack>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HeroV2
        homePage
        title={
          <Trans>Streamline Sustainable Portfolio Creation and Reporting</Trans>
        }
        description={
          <Trans>
            Sustainability Navigator serves as the ultimate platform solution,
            providing the complete toolkit to build, analyse and optimize
            sustainable portfolios, in line with current EU regulatory
            frameworks.
          </Trans>
        }
        kind="Sustainability Navigator"
      />
      <Container pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 8 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Stack spacing={1.5}>
              <Typography fontWeight={700} fontSize={16} color="#0E8F61">
                Sustainability Navigator
              </Typography>
              <Typography fontWeight={600} fontSize={36} lineHeight={1}>
                <Trans>How Sustainable is Your Portfolio?</Trans>
              </Typography>
              <Typography color="rgba(14, 35, 64, 0.60)">
                <Trans>
                  Sustainability Navigator is one stop-shop to check, build and
                  automate the calculation of sustainability features and
                  reporting for Art.8 and Art.9 SFDR funds.
                </Trans>
              </Typography>
              <Typography color="rgba(14, 35, 64, 0.60)">
                <Trans>
                  It is the ultimate platform solution crafted by our ESG expert
                  team, backed by over 15 years of industry experience.
                </Trans>
              </Typography>
              {renderRequestButton()}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              spacing={1}
              alignItems="center"
              direction={{ xs: "column", md: "row" }}
            >
              <Stack spacing={1} width="100%">
                {ITEMS.slice(0, 2).map((item) => renderItem(item))}
              </Stack>
              {renderItem(_.last(ITEMS))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 8 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={5}>
            <img width="100%" alt="first" src={firstImage} />
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={1.5}>
              <Typography fontWeight={700} fontSize={16} color="#0E8F61">
                Build
              </Typography>
              <Typography fontWeight={600} fontSize={36} lineHeight={1}>
                <Trans>
                  Build your Sustainable Investment Universe or Portfolio
                </Trans>
              </Typography>
              <Typography color="rgba(14, 35, 64, 0.60)">
                <Trans>
                  Build from scratch using an exclusive SFDR framework designed
                  by our ESG expert team aligned with the regulation.
                </Trans>
              </Typography>
              <Grid container>
                {BUILD_ITEMS.map(({ title, description }, index) => (
                  <Grid item xs={12} sm={4}>
                    <Stack direction="row" spacing={1}>
                      <StyledBox>
                        <Typography color="#0E8F61" fontWeight={600}>
                          {index + 1}
                        </Typography>
                      </StyledBox>
                      <Stack spacing={0.5}>
                        <Typography fontWeight={700}>{title}</Typography>
                        <Typography color="rgba(14, 35, 64, 0.60)">
                          {description}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              {renderRequestButton()}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Box backgroundColor="#F1F1F3" pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 8 }}>
        {/* <Container pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 8 }}> */}
        <Grid
          container
          spacing={4}
          pb={4}
          pl={{
            xs: 2,
            sm: 4,
            md: 6,
          }}
          pr={{
            xs: 2,
            sm: 0,
          }}
          alignItems="center"
        >
          <Grid item xs={12} md={5}>
            <Stack spacing={1.5}>
              <Typography fontWeight={700} fontSize={16} color="#0E8F61">
                Check
              </Typography>
              <Typography fontWeight={600} fontSize={36} lineHeight={1}>
                <Trans>Check Key Sustainable Insights of Your Portfolio</Trans>
              </Typography>
              <Typography color="rgba(14, 35, 64, 0.60)">
                <Trans>
                  Check the portfolio of an existing fund or simulate a
                  portfolio. Using an exclusive SFDR framework designed by our
                  ESG expert team aligned with the regulation.
                </Trans>
              </Typography>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                {CHECK_ITEMS.map(({ title, description }, index) => (
                  <Stack direction="row" spacing={1}>
                    <StyledBox>
                      <Typography color="#0E8F61" fontWeight={600}>
                        {index + 1}
                      </Typography>
                    </StyledBox>
                    <Stack spacing={0.5}>
                      <Typography fontWeight={700}>{title}</Typography>
                      <Typography color="rgba(14, 35, 64, 0.60)">
                        {description}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
              {renderRequestButton()}
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <img width="100%" alt="second" src={secondImage} />
          </Grid>
        </Grid>
        {/* </Container> */}
      </Box>
      <Container pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 8 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={7}>
            <img width="100%" alt="third" src={thirdImage} />
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              spacing={3}
              sx={{
                "> ul": {
                  color: "rgba(14, 35, 64, 0.60) !important",
                },
              }}
            >
              <Typography fontWeight={700} fontSize={16} color="#0E8F61">
                Sustainable Report
              </Typography>
              <Typography fontWeight={600} fontSize={36} lineHeight={1}>
                <Trans>Sustainability Assessment Report</Trans>
              </Typography>
              <Typography color="rgba(14, 35, 64, 0.60)">
                <Trans>
                  Support your sustainable strategy and selection with an
                  in-depth analysis
                </Trans>
              </Typography>
              <ul>
                <li>
                  <Trans>Percentage of Sustainable Investments</Trans>
                </li>
                <li>
                  <Trans>ESG ratings</Trans>
                </li>
                <li>
                  <Trans>Sustainable Development Goals (SDGs)</Trans>
                </li>
                <li>
                  <Trans>Controversial activities</Trans>
                </li>
              </ul>
              {renderRequestButton()}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      {/* <Box backgroundColor="#F1F1F3">
        <Container pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 8 }}>
          <Stack spacing={4}>
            <Typography fontWeight={600} fontSize={32}>
              <Trans>Use cases</Trans>
            </Typography>
            <Grid container>
              {CASES_ITEMS.map(({ title, items }) => (
                <Grid item xs={12} md={4}>
                  <Stack
                    spacing={1}
                    sx={{
                      "> ul": {
                        color: "rgba(14, 35, 64, 0.60) !important",
                      },
                    }}
                  >
                    <Typography fontWeight={700}>{title}</Typography>
                    <ul>
                      {items.map(item => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Container>
      </Box> */}
      <Footer small />
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="ESG - Sustainability Navigator"
        />
      )}
    </ThemeProvider>
  );
}

const StyledBox = styled(Box)`
  width: 42px;
  height: 42px;
  min-width: 42px;
  display: grid;
  border-radius: 50%;
  place-content: center;
  background-color: rgba(14, 143, 97, 0.2);
`;

export default EsgHome;
